import MainLayout from "../widgets/mainLayout";
import DashboardTabs from "../widgets/dashboardTabs/dashboardTabs";
import {useGetAccountDashboardQuery} from "../store/API/userApi";
import {Outlet, useLocation} from "react-router-dom";
import {
    useGetChartBalanceGainQuery,
    useGetChartBalanceQuery,
    useGetChartDayQuery,
    useGetChartDrawdownAndGainQuery,
    useGetChartDrawdownQuery,
    useGetChartHoursQuery,
    useGetChartMonthsQuery,
    useGetChartSymbolQuery, useGetChartTradersQuery
} from "../store/API/chartApi";
import React, {createContext, useEffect, useState} from "react";
import {Skeleton} from "@mui/material";

export const HiddenChartContext = createContext<any>(null)

const AccountDashboard = () => {
    const location = useLocation()
    const id = isNaN(Number(location?.pathname?.split('/').pop())) ? location?.pathname?.split('/')?.at(-2) : location?.pathname?.split('/').pop()
    const splitLocation = location.pathname?.split('/')

    let skipRequest = false
    let skipRequestAccount = false

    splitLocation?.forEach((el) => {
        if (el === 'open-position' || el === 'trade' || el === 'history') {
            skipRequest = true
        }
    })
    splitLocation?.forEach((el) => {
        if (el === 'open-position' || el === 'history') {
            skipRequestAccount = true
        }
    })
    console.log(id)
    console.log(skipRequest)
    console.log(skipRequest && skipRequestAccount, 'skip')
    const [balanceChartUrl, setBalanceChartUrl] = useState('all')
    const [balanceGainChartUrl, setBalanceGainChartUrl] = useState('all')
    const [tradersChartUrl, setTradersChartUrl] = useState('current-month')
    const [dayChartUrl, setDayChartUrl] = useState('all')
    const [hoursChartUrl, setHoursChartUrl] = useState('all')
    const [drawdownChartUrl, setDrawdownChartUrl] = useState('all')
    const [drawdownAndGainChartUrl, setDrawdownAndGainChartUrl] = useState('all')

    const [isHiddenBalanceChart, setIsHiddenBalanceChart] = useState(true)
    const [isHiddenBalanceGainChart, setIsHiddenBalanceGainChart] = useState(true)
    const [isHiddenDrawdownChart, setIsHiddenDrawdownChart] = useState(true)
    const [isHiddenDrawdownAndGainChart, setIsHiddenDrawdownAndGainChart] = useState(true)
    const [isHiddenDataChartMonths, setIsHiddenDataChartMonths] = useState(true)

    const {
        data: dataAccount,
        isLoading: isLoadingAccount,
        error: errorAccount,
        refetch: refetch1
    } = useGetAccountDashboardQuery(id, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
        // skip: skipRequest && skipRequestAccount
    })

    const {data, isLoading, error, refetch: refetch2} = useGetChartBalanceQuery({
        id: `account/${id}`,
        url: balanceChartUrl
    }, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
        skip: skipRequest || isHiddenBalanceChart
    })
    console.log(data, 'data')
    // const {
    //     data: dataTradersChart,
    //     isLoading: isLoadingTradersChart,
    //     error: error1,
    //     refetch: refetch3
    // } = useGetChartTradersQuery({
    //     id: `account/${id}`,
    //     url: tradersChartUrl
    // }, {
    //     refetchOnReconnect: true,
    //     refetchOnMountOrArgChange: true,
    //     skip: skipRequest
    // })
    const {
        data: dataBalanceGain,
        isLoading: isLoadingBalanceGain,
        error: error2,
        refetch: refetch4
    } = useGetChartBalanceGainQuery({
        id: `account/${id}`,
        url: balanceGainChartUrl
    }, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
        skip: skipRequest || isHiddenBalanceGainChart
    })

    const {
        data: dataChartMonths,
        isLoading: isLoadingChartMonths,
        error: error6,
        refetch: refetch8
    } = useGetChartMonthsQuery({
        id: `account/${id}`,
        url: 'all'
    }, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
        skip: skipRequest || isHiddenDataChartMonths
    })
    console.log(dataChartMonths)

    const {
        data: dataChartDrawdown,
        isLoading: isLoadingChartDrawdown,
        error: error7,
        refetch: refetch9
    } = useGetChartDrawdownQuery({
        id: `account/${id}`,
        url: drawdownChartUrl
    }, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
        skip: skipRequest || isHiddenDrawdownChart
    })
    const {
        data: dataChartDrawdownAndGain,
        isLoading: isLoadingChartDrawdownAndGain,
        error: error8,
        refetch: refetch10
    } = useGetChartDrawdownAndGainQuery({id: `account/${id}`, url: drawdownAndGainChartUrl}, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
        skip: skipRequest || isHiddenDrawdownAndGainChart
    })

    const updateDeposit = () => refetch1()
    console.log(dataAccount?.data?.product?.product_data)
    return (
        <HiddenChartContext.Provider value={{
            setIsHiddenBalanceChart: setIsHiddenBalanceChart,
            isHiddenBalanceChart: isHiddenBalanceChart,
            isLoadingBalanceChart: isLoading,

            setIsHiddenBalanceGainChart: setIsHiddenBalanceGainChart,
            isHiddenBalanceGainChart: isHiddenBalanceGainChart,
            isLoadingBalanceGain: isLoadingBalanceGain,

            isHiddenDrawdownChart: isHiddenDrawdownChart,
            setIsHiddenDrawdownChart: setIsHiddenDrawdownChart,
            isLoadingChartDrawdown: isLoadingChartDrawdown,

            isHiddenDrawdownAndGainChart: isHiddenDrawdownAndGainChart,
            setIsHiddenDrawdownAndGainChart: setIsHiddenDrawdownAndGainChart,
            isLoadingChartDrawdownAndGain: isLoadingChartDrawdownAndGain,

            isHiddenDataChartMonths: isHiddenDataChartMonths,
            setIsHiddenDataChartMonths: setIsHiddenDataChartMonths,
            isLoadingChartMonths: isLoadingChartMonths,
        }}>
            <MainLayout isSelect isLink={true} heading="Дашборд" accountNumber={dataAccount?.data?.login}
                        typeAccount={(dataAccount?.data?.product?.product_data?.slug === 'robot' && 'Робот Aggressive') || (dataAccount?.data?.product?.product_data?.slug === 'robot2' && 'Робот Conservative') || dataAccount?.data?.product?.product_data?.title}>
                {
                    (
                        !isLoadingAccount
                        // &&
                        // !isLoading &&
                        // !isLoadingTradersChart
                        // !isLoadingBalanceGain &&
                        // !isLoadingChartMonths
                        // !isLoadingChartDrawdown &&
                        // !isLoadingChartDrawdownAndGain
                    )
                        ?
                        <DashboardTabs
                            isTraderDasboard={false}
                            updateDeposit={updateDeposit}
                            isLoadingAccount={isLoadingAccount}
                            dataDashboard={dataAccount?.data}
                            balanceChartData={data}
                            // dataTradersChart={dataTradersChart}
                            dataChartDrawdown={dataChartDrawdown}
                            dataBalanceGain={dataBalanceGain}
                            dataChartDrawdownAndGain={dataChartDrawdownAndGain}
                            dataChartMonths={dataChartMonths}

                            balanceChartUrl={setBalanceChartUrl}
                            tradersChartUrl={setTradersChartUrl}
                            balanceGainChartUrl={setBalanceGainChartUrl}
                            dayChartUrl={setDayChartUrl}
                            hoursChartUrl={setHoursChartUrl}
                            drawdownChartUrl={setDrawdownChartUrl}
                            drawdownAndGainChartUrl={setDrawdownAndGainChartUrl}

                        />
                        : <Skeleton variant="rounded" width={`100%`} height={433}/>
                }

            </MainLayout>
        </HiddenChartContext.Provider>
    );
};

export default AccountDashboard;
