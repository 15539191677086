import React, {FC, useState} from 'react';
import AccountCard from "../entities/components/accountCard/accountCard";
import {Grid, Stack, useMediaQuery, Skeleton, Pagination, Snackbar, Alert, Divider, Modal, Box} from "@mui/material";
import IconPlus from "../shared/assets/images/icons/iconPlus";
import AccountModal from "../entities/components/modal/accountModal";
import {IUserAccounts} from "../types";
import {useGetAccountsQuery} from "../store/API/userApi";
import Paper from "@mui/material/Paper";
import IconClose from "../shared/assets/images/icons/iconClose";
import {ReactComponent as ForexAddedIcon} from "../shared/assets/images/icons/forexAdded.svg";
import {ReactComponent as FinamAddedIcon} from "../shared/assets/images/icons/finamAdded.svg";

interface T {
}

const AccountCardList: FC<T> = ({}) => {
    const [accountPage, setAccountPPage] = useState(1);

    const {data, error, isLoading} = useGetAccountsQuery(accountPage, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const mediaQuery = useMediaQuery('(min-width:980px)');
    const [openModal, setOpenModal] = useState<boolean | string>(false);
    const [openModalAdded, setOpenModalAdded] = useState(false);

    if (error) {
        return (
            <Paper>
                <span className="h1">Не удалось загрузить данные счета</span>
            </Paper>
        )
    }

    const handleChangeAccountPage = (event: React.ChangeEvent<unknown>, value: number) => {
        setAccountPPage(value);
    };
    return (
        <>
            <Grid container spacing={10} columns={16} wrap="wrap">
                <Grid item xs={16} md={8} onClick={() => setOpenModalAdded(true)} sx={{cursor: "pointer"}}>
                    <Stack sx={{
                        height: mediaQuery ? 400 : 110,
                        border: ` 0.5px dashed #3C3C3C`,
                        borderRadius: 2.5,
                        overflow: 'hidden'
                    }}>
                        <Stack justifyContent="center" alignItems="center" sx={{height: `100%`}}>
                            <Stack sx={{mb: 7}}>
                                <IconPlus size={20} color="#3C3C3C"/>
                            </Stack>
                            <Stack className="white-80">Добавить счет</Stack>
                        </Stack>
                    </Stack>
                </Grid>
                {
                    isLoading ?
                        <Grid item xs={16} md={8}>
                            <Stack sx={{
                                height: 400,
                                border: ` 0.5px solid #3C3C3C`,
                                borderRadius: 2.5,
                                overflow: 'hidden'
                            }}
                                   spacing={7}>
                                <Skeleton variant="rounded" width={`100%`} height={`100%`}/>
                            </Stack>
                        </Grid>
                        :
                        data?.data.map((item: IUserAccounts, index: number) => {

                                return <Grid item xs={16} md={8} key={item.id}>
                                    <AccountCard
                                        index={index}
                                        reject_reason={item.reject_reason}
                                        isLoading={isLoading}
                                        accountId={item.id}
                                        accountPassword={item.password}
                                        accountLogin={item.login}
                                        type={item?.product?.product_data?.type}
                                        product_data={item?.product?.product_data}
                                        server={{id: item.server.id, title: item.server.title}}
                                        accountName={item.name}
                                        status={item.status}
                                        productType={item?.product?.product_data?.title}
                                        balance={item?.stats?.balance}
                                        accountNumber={item.id}
                                        accountType={item.server}
                                        depositLoad={item?.stats?.deposit_load}
                                    />
                                </Grid>
                            }
                        )
                }
            </Grid>
            {
                openModal &&
                <AccountModal openModal={openModal} closeModal={setOpenModal}/>
            }

            {openModalAdded && <Modal open={openModalAdded} onClose={() => setOpenModalAdded(false)}>
                <Box sx={{maxWidth: 620}}>
                    <Stack onClick={() => setOpenModalAdded(false)}
                           sx={{position: "absolute", top: 14, right: 28, cursor: "pointer"}}>
                        <IconClose/>
                    </Stack>
                    <Stack className="h2 white-90" sx={{mb: 7}}>{'Добавить счет'}</Stack>
                    <Divider variant="fullWidth" sx={{mb: 7}}/>
                    <Stack direction="column" justifyContent="center" spacing={7} sx={{mt: 7}}>
                        <Stack onClick={() => {
                            setOpenModal('forex')
                            setOpenModalAdded(false)
                        }} alignItems={'center'} justifyContent={'center'}
                               sx={{
                                   width: '100%',
                                   backgroundColor: 'rgba(43, 43, 43, 1)',
                                   borderRadius: '10px',
                                   padding: '20px',
                                   cursor: 'pointer',
                                   transition: '.3s all',
                                   '&:hover': {
                                       backgroundColor: 'rgba(43, 43, 43, .6)',
                                   }
                               }}>
                            <div>
                                <ForexAddedIcon/>
                            </div>
                            <div style={{marginTop: '5px'}}>
                                <span className="subHeadersBold white-90">(Копитен)</span>
                            </div>
                        </Stack>
                        <Stack onClick={() => {
                            setOpenModal('stock')
                            setOpenModalAdded(false)
                        }} alignItems={'center'} justifyContent={'center'}
                               sx={{
                                   width: '100%',
                                   backgroundColor: 'rgba(43, 43, 43, 1)',
                                   borderRadius: '10px',
                                   padding: '20px',
                                   cursor: 'pointer',
                                   transition: '.3s all',
                                   '&:hover': {
                                       backgroundColor: 'rgba(43, 43, 43, .6)',
                                   }
                               }}>
                            <div>
                                <FinamAddedIcon/>
                            </div>
                            <div style={{marginTop: '5px'}}>
                                <span className="subHeadersBold white-90">(Фондовый Робот)</span>
                            </div>
                        </Stack>
                    </Stack>
                </Box>
            </Modal>}

            {
                data?.meta?.pagination?.total_pages > 1 &&
                <Pagination
                    page={accountPage}
                    onChange={handleChangeAccountPage}
                    color="primary"
                    count={data?.meta?.pagination?.total_pages}
                    variant="outlined"
                    shape="rounded"
                    sx={{mr: 'auto', ml: 'auto', mt: 14, maxWidth: 'max-content'}}
                />

            }
        </>
    );
};

export default AccountCardList;
