import React, {FC, useEffect, useState} from 'react';
import InputSetting from "../../../widgets/dashboardTabs/tradePage/tarde_speculative/setings/inputSetting";
import {
    useGetPaymentLinkMutation,
    useGetProductDataByIdQuery, useGetProductDataBySlugMutation,
    useGetWalletsQuery,
} from "../../../store/API/productApi";
import {
    Box,
    Checkbox,
    Divider,
    FormControlLabel,
    Modal, Radio, RadioGroup,
    Stack,
} from "@mui/material";
import IconClose from "../../../shared/assets/images/icons/iconClose";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";

interface IType {
    paymentLinkId?: any;
    stateModal?: any;
    title?: string;
    text?: string;
    price?: any;
    forexAccountData?: any;
    closeModal?: any;
    openModal: boolean;
    isRobot?: boolean;
    slug?: any;
    // product?: any;
}

const PaymentModal: FC<IType> = ({
                                     isRobot,
                                     paymentLinkId,
                                     // forexAccountData,
                                     title,
                                     price,
                                     text,
                                     openModal,
                                     closeModal,
                                     stateModal,
                                     slug,
                                     forexAccountData
                                     // ,
                                     // product
                                 }) => {


    const {data: wallets} = useGetWalletsQuery(`/wallets`, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    const {data: userProductData} = useGetProductDataByIdQuery(`/product/${paymentLinkId ?? openModal}`, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    const walletDict = {
        'REFERRAL-WALLET': 'Реферальный',
        'COMMISSION-WALLET': 'Комиссионный'
    };

    const [open, setOpen] = useState(false);
    const [accept, setAccept] = useState(false)
    const [useWallet, setUseWallet] = useState(false)
    const [useWalletAmount, setUseWalletAmount] = useState(0)
    const [wallet, setWallet] = useState<any>(null)
    const [step, setStep] = useState(1)
    const [paymentStatus, setPaymentStatus] = useState(null)
    // const [productData, setProductData] = useState<any>(null)
    const [getPaymentLink, {
        data: dataPayLink,
        error: productError,
        isLoading: productLoading
    }] = useGetPaymentLinkMutation()

    const [getProductData, {
        data: productData,
    }] = useGetProductDataBySlugMutation()
    const getWalletTitle = (key: string) => {
        // @ts-ignore
        return walletDict[key] ?? '';
    }

    const handleClose = (e: any) => {
        e.preventDefault()
        closeModal(false)
        setOpen(false)
    };

    const processPayment = () => {
        let data = {
            use_wallet: null
        }
        if (useWallet) {
            // @ts-ignore
            data.use_wallet = {
                slug: wallet,
                amount: useWalletAmount
            }
        }
        getPaymentLink({
            body: data,
            id: `product/${userProductData.data.id}/pay`
        }).then((res: any) => {
            console.log(res);
            // @ts-ignore
            setPaymentStatus('Обработка...');
            if (res?.error && !res?.data) {
                // @ts-ignore
                setPaymentStatus(`${res.error.data.message}`);
            } else {
                if (res.data?.payment_url) {
                    // @ts-ignore
                    setPaymentStatus('Перенаправление на страницу оплаты...');
                    window.location.replace(res.data.payment_url);
                } else if (res.data?.from_wallet) {
                    // @ts-ignore
                    setPaymentStatus('Оплата с кошелька прошла успешно');

                    toast.success('Оплата с кошелька прошла успешно!', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });

                    closeModal(false)
                    setOpen(false)
                }
            }

        })
    };

    const nextStep = () => {
        setStep(step + 1);
        console.log(step);
    };
    console.log(step);
    useEffect((() => {
        setOpen(openModal)
    }), [open, openModal, paymentLinkId, wallets, productData, userProductData])

    useEffect((() => {
        if (userProductData?.data?.slug.length > 0) {
            getProductData(userProductData.data.slug).then()
        }
    }), [userProductData])

    useEffect((() => {
        !wallet && wallets
        && !wallets.data.every((item: { balance: number; }) => item.balance < 1)
        && setWallet(wallets.data.find((item: { balance: number; }) => item.balance >= 1).key)
    }), [wallets])

    useEffect(() => {
        if (useWallet) {
            const findUsedWalletBalance = wallet ? wallets?.data?.find((f: any) => f.key === wallet)?.balance : null
            setUseWalletAmount(findUsedWalletBalance ? (findUsedWalletBalance >= productData?.price ? productData?.price : findUsedWalletBalance) : 0)
        } else {
            setUseWalletAmount(0)
        }

    }, [wallet, wallets, useWallet, productData?.price])

    return (
        <>
            wallets && productData && userProductData && <Modal open={open} onClose={handleClose} sx={{
            "@media (max-width:980px)": {
                maxWidth: '95%',
                width: '100%',
                margin: '0 auto',
            }
        }}>
            <Box sx={{maxWidth: 620}}>
                <Stack onClick={handleClose} sx={{position: "absolute", top: 14, right: 28, cursor: "pointer"}}>
                    <IconClose/>
                </Stack>
                <Stack className="h2 white-90" sx={{mb: 7}}>{title}</Stack>
                <Divider variant="fullWidth" sx={{mb: 7}}/>
                <Stack spacing={7}>
                    {
                        // TODO: УБРАТЬ ПЕРЕХОД НА СЛЕДУЮЩИЙ ЭТАП
                        (stateModal?.id === 5 && step === 1 && nextStep()) ||

                        !text && step === 1 && <>
                            <Stack className="h2 white-100" spacing={14}>
                                {
                                    <>
                                        <span>
                                            <span>Вы хотите подключить продукт</span>
                                            <span
                                                className="yellow">&nbsp;{userProductData?.forex_account?.account_data?.name ?? productData?.name}&nbsp;</span>
                                            <span>на счет</span>
                                            <span
                                                className="blue">&nbsp;{userProductData?.forex_account?.account_data?.login ?? forexAccountData?.login}</span>
                                            <span> ?</span>
                                        </span>

                                        {isRobot && <div>
                                            <p style={{
                                                textAlign: 'left'
                                            }}>
                                                К продукту робот можно привязать только один счет. <br/>
                                                После оплаты смена счета невозможна.
                                                Для подключения другого счета потребуется создать и оплатить новый
                                                продукт робот.
                                            </p>
                                            <div style={{
                                                marginTop: '14px',
                                            }}>
                                                <Checkbox checked={accept} onClick={() => setAccept(!accept)}/>
                                                <span style={{
                                                    fontSize: '12px',
                                                    marginLeft: '10px'
                                                }}>Согласен(а)</span>
                                            </div>

                                        </div>}

                                        <Stack justifyContent="flex-end">
                                            <Button
                                                onClick={nextStep}
                                                sx={{ml: 'auto', maxWidth: 268}}
                                                color="success"
                                                disabled={isRobot ? !accept : false}
                                            >Подтвердить</Button>
                                        </Stack>
                                    </>
                                }
                            </Stack>
                        </>
                    }

                    {
                        paymentStatus && <>
                            {paymentStatus}
                        </>
                    }

                    {
                        !paymentStatus && (step >= 2 || text) && <>
                            <Stack spacing={7}>
                                <>
                                        <span>
                                            <span>продукт:</span>
                                            <span
                                                className="green">&nbsp;{productData?.name}</span>
                                            <br/>
                                            <span>Стоимость продления:</span>
                                            <span>&nbsp;{productData?.price}</span>
                                        </span>

                                </>
                                <div onClick={() => setUseWallet(!useWallet)}><>
                                    <span

                                        style={{
                                            marginTop: '14px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <Checkbox checked={useWallet}/>
                                        <span style={{
                                            fontSize: '12px',
                                            marginLeft: '10px'
                                        }}>Использовать внутренний баланс</span>
                                    </span>
                                </>
                                </div>
                                {
                                    useWallet && <RadioGroup
                                        // onChange={() => {setUseWalletAmount(0)}}
                                        style={{
                                            padding: '0 15px 0 15px'
                                        }}
                                        value={wallet}
                                    >
                                        {
                                            wallets && wallets?.data.map((el: {
                                                value: any;
                                                key: any;
                                                balance: any;
                                            }) => {
                                                // @ts-ignore
                                                return <FormControlLabel
                                                    style={{
                                                        width: '100%',
                                                        opacity: el.key !== wallet ? 0.3 : 1
                                                    }}
                                                    onClick={() => {
                                                        el.balance >= 1 && setWallet(el.key);
                                                        el.key !== wallet && setUseWalletAmount(0);
                                                    }}
                                                    key={el.key}
                                                    value={el.key}
                                                    control={<Radio disabled={el.balance < 1} size="small"/>}
                                                    label={
                                                        <>
                                                            <div style={{
                                                                display: 'flex'
                                                            }}>
                                                                <span style={{
                                                                    padding: "5px",
                                                                    fontSize: "12px",
                                                                    alignContent: 'center'
                                                                }}>
                                                                    {`${getWalletTitle(el.key)} (${el.balance})`}
                                                                </span>
                                                                <span style={{
                                                                    padding: "5px",
                                                                    maxWidth: "100px"
                                                                }}>
                                                                    {el.key === wallet && <InputSetting
                                                                        value={useWalletAmount}
                                                                        label=""
                                                                        type="number"
                                                                        min={1}
                                                                        max={Math.min(el.balance, productData?.price)}
                                                                        onChange={(value: any) => {
                                                                            setUseWalletAmount(value);
                                                                        }}
                                                                        sx={{color: '#828282'}}/>}
                                                                </span>
                                                            </div>
                                                        </>
                                                    }
                                                />
                                            })
                                        }
                                    </RadioGroup>
                                }
                                <Stack justifyContent="flex-end">
                                    <Button
                                        sx={{ml: 'auto', maxWidth: 268}}
                                        color="success"
                                        disabled={isRobot ? !accept : false}
                                        onClick={processPayment}
                                    >Оплатить</Button>
                                </Stack>
                            </Stack>
                        </>
                    }
                </Stack>
            </Box>
        </Modal>
        </>
    );
};
export default PaymentModal;
