import React, {FC} from 'react';
import {Skeleton, Stack} from "@mui/material";
import {useAppSelector} from "../../hooks/useRedux";
import {useGetProfileQuery} from "../../store/API/profileApi";

interface T {
    data?: any;
    isTrader?: any;
}


const TransactionsLabel: FC<T> = ({data, isTrader}) => {
    const {accountId} = useAppSelector(state => state.accountIdReducer)
    console.log(data)
    // const {data: data_deposit, error} = useGetProfileQuery('', {
    //     refetchOnReconnect: true,
    //     refetchOnMountOrArgChange: true
    // })
    // console.log(data_deposit?.data?.accounts)
    const dataLabel = [
        {
            title: 'Всего сделок',
            value: data?.deals?.all?.count,
            colorText: 'white-100',
            colorBg: '',
        },
        {
            title: 'Доходных сделки',
            value: data?.deals?.profitable?.count,
            colorText: 'green',
            colorBg: 'greenBg',
        },
        {
            title: 'Убыточные сделки',
            value: data?.deals?.losing?.count,
            colorText: 'red',
            colorBg: 'redBg',
        },
        {
            title: 'Нагрузка депозита',
            value: isTrader ? `${data?.deposit_load.for_forex_accounts[accountId]}%` : `${data?.deposit_load}%`,
            // value: isTrader ? `${data_deposit?.data?.accounts?.find((f: any) => f.id === accountId)?.deposit_load}%` : `${data?.deposit_load}%`,
            colorText: 'blue',
            colorBg: 'blueBg',
        },

    ]
    return (
        <>
            <Stack
                sx={{
                    display: 'grid', gridTemplateColumns: 'repeat(4,1fr)',
                    "@media (max-width: 780px)": { // Заменяем "960px" на вашу точку
                        gridTemplateColumns: "repeat(2, 1fr)",
                    },

                }} gap={7}
                // direction="row" flexWrap="wrap" gap={7} justifyContent="space-between"
            >
                {
                    dataLabel && dataLabel.map((item, index: any) => (
                        data ?
                            <Stack
                                key={item.title}
                                className={item.colorBg}
                                sx={{
                                    height: 140,
                                    // width: 140,
                                    width: '100%',
                                    padding: 7,
                                    border: `0.5px solid #3C3C3C`,
                                    borderRadius: 2,

                                    "@media (max-width: 1280px)": {
                                        // width: 'calc(50% - 14px)'
                                    }
                                }}
                                justifyContent="space-between"
                            >
                                <span className="h2 white-90">{item.title}</span>
                                <span className={item.colorText + " h2"}>{item.value}</span>
                            </Stack>
                            :
                            <Skeleton key={index} variant="rounded" width={140} height={140}/>
                    ))
                }
            </Stack>

        </>
    );
};

export default TransactionsLabel;
