import React, {useState} from 'react';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import classNames from "classnames";
import s from "./styles.module.css";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TableBody from "@mui/material/TableBody";
import {spaceBtwHundreds} from "../../../utils/spaceBetweenSum";
import {toast} from "react-toastify";
import {ReactComponent as PlusIcon} from "../../../../shared/assets/images/icons/plusTable.svg";
import TableContainer from "@mui/material/TableContainer";
import {rowStyleText} from "./index";
import FilterListIcon from '@mui/icons-material/FilterList';
import {useSearchParams} from "react-router-dom";

const headers = [
    {
        title: '-',
        sort: 1,
        path: 'symbol'
    }, {
        title: 'Оборот в деньгах',
        sort: 1,
        path: 'turnover_in_money'
    }, {
        title: 'Рейт. AKRA',
        sort: 1,
        path: 'r_akra'
    }, {
        title: 'Рейт. EXPERT',
        sort: 1,
        path: 'r_expert'
    }, {
        title: 'Рейт. FITCH',
        sort: 1,
        path: 'r_fitch'
    }, {
        title: 'Рейт. MOODY',
        sort: 1,
        path: 'r_moody'
    }, {
        title: 'Рейт. SP',
        sort: 1,
        path: 'r_sp'
    }
    // , {
    //     title: 'Действие',
    //     // sort: 1
    // }
]

const TableStock = ({handlerClose, data, setTools, tools, handleData, params, setParams}: any) => {
    const [paramsSearch, setParamsSearch] = useSearchParams()

    const handleSort = (path: any, sort: any) => {
        setParams({
            ...params,
            sort: {
                path: path,
                sort: sort
            }
        })
        handleData({
            ...params, sort: {
                path: path, sort: sort
            }
        })
    }
    return (
        <TableContainer
            sx={{maxHeight: '100%', border: '1px solid #3C3C3C', background: '#1F1F1F'}}>
            <Table stickyHeader aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {
                            headers.map((item: any, index: any) =>
                                <TableCell onClick={() => {
                                    if (!item.sort) return
                                    if (item.path !== params?.sort.path) {
                                        handleSort(item?.path, 2)
                                    } else {
                                        handleSort(item?.path, (params?.sort.sort === 1 && 2) || (params?.sort.sort === 2 && 3) || (params?.sort.sort === 3 && 1))
                                    }

                                }} sx={{
                                    ...rowStyleText,
                                    cursor: item.path && 'pointer',
                                    userSelect: 'none'
                                }}
                                           key={index}>
                                    <div
                                        className={classNames(s.header_item, (item?.path === params?.sort?.path && +params?.sort?.sort === 2) && s.header_item_active, (params?.sort?.sort !== 1 && params?.sort?.sort && params?.sort.path === item.path) && s.active_sort)}>
                                        {item?.title}
                                        {item?.path ? (
                                            (item?.path === params?.sort.path && params?.sort.sort === 2 &&
                                                <KeyboardArrowUpIcon/>) ||
                                            (item?.path === params?.sort.path && params?.sort.sort === 3 &&
                                                <KeyboardArrowUpIcon/>) ||
                                            <FilterListIcon/>
                                        ) : null}
                                    </div>
                                </TableCell>
                            )
                        }
                    </TableRow>
                </TableHead>
                <TableBody sx={{maxHeight: '500px'}}>
                    {data?.data?.map((item: any, index: number) => (
                        <TableRow key={index} sx={{
                            transition: '.3s all',
                            background: tools?.id === item?.id ? '#2A3336' : '',
                            '&:hover': {
                                backgroundColor: tools?.id === item?.id ? '#2A3336' : '#181818'
                            }
                        }}>

                            <TableCell sx={{
                                minWidth: '250px', ...rowStyleText,
                                fontWeight: 600,
                                fontSize: '14px',
                                textAlign: 'left',
                                paddingLeft: '18px',
                                cursor: 'pointer',
                                color: '#fff'
                            }} onClick={() => {
                                paramsSearch.set('symbolID', item?.id)
                                setParamsSearch(paramsSearch)
                                setTools(item)
                                handlerClose()
                            }}>
                                {`(${item?.symbol}) - ${item.description}`}
                            </TableCell>

                            <TableCell sx={{minWidth: '165px', ...rowStyleText, color: '#F2C94C'}}>
                                {item.turnover_in_money ? `${spaceBtwHundreds(item.turnover_in_money || 0)} ₽` : '-'}
                            </TableCell>

                            <TableCell
                                sx={{
                                    minWidth: '130px', ...rowStyleText,

                                }}>
                                {item.r_akra || '-'}
                            </TableCell>
                            <TableCell
                                sx={{
                                    minWidth: '130px', ...rowStyleText,

                                }}>
                                {item.r_expert || '-'}
                            </TableCell>
                            <TableCell
                                sx={{
                                    minWidth: '130px', ...rowStyleText,

                                }}>
                                {item.r_fitch || '-'}
                            </TableCell>
                            <TableCell
                                sx={{
                                    minWidth: '130px', ...rowStyleText,

                                }}>
                                {item.r_moody || '-'}
                            </TableCell>
                            <TableCell
                                sx={{
                                    minWidth: '130px', ...rowStyleText,

                                }}>
                                {item.r_sp || '-'}
                            </TableCell>

                            {/*<TableCell onClick={() => {*/}
                            {/*    toast.warning('В разработке')*/}
                            {/*}} sx={{*/}
                            {/*    minWidth: '115px',*/}
                            {/*    width: '115px',*/}
                            {/*    cursor: 'pointer',*/}
                            {/*    maxWidth: '115px', ...rowStyleText*/}
                            {/*}}>*/}
                            {/*    <div className={s.added}>*/}
                            {/*        <PlusIcon/>*/}
                            {/*        Добавить*/}
                            {/*    </div>*/}
                            {/*</TableCell>*/}

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableStock;