import React, {useState} from 'react';
import CustomOpenPositionsTable from "../../shared/components/customOpenPositionsTable";
import {useGetOpenPositionsQuery} from "../../store/API/tradersUserApi";
import {useLocation} from "react-router-dom";

const Tabs3 = ({isTrader, dataTrader}: any) => {
    const location = useLocation()
    const id = location?.pathname?.split('/').at(-2)
    console.log(location?.pathname?.split('/'))
    console.log(id)
    const [page, setPage] = useState(1);
    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const {
        data: dataOpenPositions,
        isLoading: isLoadingHistory
    } = useGetOpenPositionsQuery({id: `account/${id}`, page},
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true
        }
    )

    // время открытия - open_at
    // тип - так же как и раньше (type)
    // объем - volume
    // символ - symbol
    // цена - open_price
    // стратегия - strategy_id (0 - спекулятивная, 1 - основная)

    return (
        <>
            <CustomOpenPositionsTable
                changePage={handleChangePage} page={page}
                isTrader={isTrader}
                data={dataOpenPositions}
                dataTableHead={[
                    'Время открытия', 'Тип', 'Обьем', 'Символ', 'Цена', 'Стратегия'
                ]}
            />
        </>
    );
};

export default Tabs3;
