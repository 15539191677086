import React, {FC} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Pagination} from "@mui/material";


interface T {
    data?: any;
    dataTableHead?: string[];
    isTrader: any;
    changePage?: any;
    page?: any;
}

const CustomOpenPositionsTable: FC<T> = ({data, dataTableHead, isTrader, changePage, page}) => {
    return (
        <>

            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {
                                dataTableHead &&
                                dataTableHead.map((item: any, index: any) =>
                                    <TableCell sx={{
                                        "@media (max-width: 960px)": {
                                            minWidth: "130px"
                                        },
                                    }} key={item + index}>{item}</TableCell>
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/*
                        'type' => 'buy',
                        'open_at' => Carbon::parse($forexClosedOrder->open_at)->format('Y.m.d H:i'),
                        'symbol' => $symbol?->name,
                        'open_price' => $forexClosedOrder->target_price === null?: $forexClosedOrder->target_price,
                        'strategy_id' => $forexClosedOrder->startegy_id,
                        'volume' => $forexClosedOrder->ex_lot === null?: round($forexClosedOrder->ex_lot, 2),
                        */}
                        {data?.data?.map((item: any, index: number) => (
                            <TableRow key={item?.trader?.name + item.open_at + item.profit + ' ' + index}>

                                <TableCell>{item?.open_at ?? '-'}</TableCell>
                                {/*{!isTrader &&*/}
                                {/*    <TableCell>*/}
                                {/*        {item.trader?.name}*/}
                                {/*        {item?.set?.name && <span className="green"> (Сет {item?.set?.name})</span>}*/}
                                {/*    </TableCell>*/}
                                {/*}*/}

                                <TableCell className={item?.type === 'sell' ? 'red' : 'green'}>
                                    {item?.type === 'sell' ? 'Продажа' : 'Покупка'}
                                </TableCell>
                                <TableCell>
                                    <span className="yellow">{item?.volume ?? '-'}</span>
                                </TableCell>
                                <TableCell sx={{color: '#fff'}}>{item?.symbol ? item?.symbol : '-'}</TableCell>
                                <TableCell sx={{color: '#fff'}}>{item?.open_price}</TableCell>
                                <TableCell>
                                    {item.strategy_id === 0 ? 'Спекулятивная' : '-'}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {
                data?.meta?.pagination?.total_pages > 1 &&
                <Pagination
                    page={page}
                    onChange={changePage}
                    color="primary"
                    count={data?.meta?.pagination?.total_pages}
                    variant="outlined"
                    shape="rounded"
                    sx={{mr: 'auto', mt: 7}}
                />

            }
        </>
    );
};

export default CustomOpenPositionsTable;
