import React from 'react';
import s from './styles.module.css'
import {Step, Title} from "../step";
import img_step_1 from '../assets/step1.png'
import img_step_1_s from '../assets/step_1_s.png'
import img_step_2 from '../assets/step2.png'
import {useMediaQuery} from "@mui/material";

const BoxBorder = ({children, style, type = 1}: any) => {
    return <div style={style}
                className={
                    (type === 1 && s.box_border) ||
                    (type === 2 && s.box_border_v2) ||
                    (type === 3 && s.box_border_v3)
                }>{children}</div>
}

const Step1 = ({url, type}: any) => {
    const mediaQuery = useMediaQuery('(min-width:769px)');

    return (
        <div style={{paddingBottom: '14px'}}>
            {/*<Step step={1}/>*/}

            <div style={{marginTop: '14px'}}>
                <Title title={(type === 'stock' ? '1. ' : '') + 'Пройти регистрацию у брокера'}/>
            </div>

            {/*<div className={s.box_step_1}>*/}
            {/*<div className={s.box_step_1_left}>*/}
            <div className={s.box_step_1_item}>
                <p className={s.box_step_1_title}>Зайдите на сайт по ссылке</p>
                <a href={url} target={'_blank'}><p
                    className={s.link}>{url}</p></a>
            </div>

            <BoxBorder style={{marginTop: '14px', padding: '14px 14px'}}>
                {
                    type !== 'stock' && <>
                        <p style={{fontWeight: 600}}
                           className={`${s.important_sub_title} ${s.important_sub_title_green}`}>Как пополнить счет выгоднее
                            на 2-5% чем у форекс брокера</p>

                        <p className={s.step_box_6_sub_title}>Для этого нужно зарегистрироваться на криптобирже</p>


                        <div style={{marginTop: '8px'}} className={s.btn_link}><a
                            href="https://www.bybit.com/invite?ref=VJN7K1"
                            target={'_blank'}>Регистрация Bybit</a>
                        </div>
                        {/*<div style={{marginTop: '8px'}} className={s.btn_link}><a*/}
                        {/*    href="https://www.binance.com/ru/activity/referral-entry/CPA/incremental?ref=CPA_00WN5EK04R"*/}
                        {/*    target={'_blank'}>Регистрация Binance</a></div>*/}
                    </>
                    || type === 'stock' && <>

                        {/*<p style={{fontWeight: 600}}*/}
                        {/*   className={`${s.important_sub_title} ${s.important_sub_title_green}`}>Как пополнить счет выгоднее*/}
                        {/*    на 2-5% чем у форекс брокера</p>*/}

                        <p>
                            2. Авторизоваться в сервисе <a href={'https://www.comon.ru/'} className={s.link} target={'_blank'}>Common</a>. с логином и
                            паролем от финам кабинета
                        </p>
                        <p style={{marginTop: '12px'}}>
                            3. В личном кабинете Comon <a href={'https://www.comon.ru/my/trade-api/tokens'} className={s.link} target={'_blank'}>получить
                            токен</a>.
                        </p>


                    </>
                }
            </BoxBorder>
            {
                type !== 'stock' && <Title title={'И далее делаете все по инструкции из видео ниже'} style={{marginTop: '14px'}}/>
                || type === 'stock' && <Title title={'Посмотрите видеоинструкцию'} style={{marginTop: '14px'}}/>
            }


            {/*<iframe style={{marginTop: '8px'}} width="100%" height={mediaQuery ? "432" : "211"}*/}
            {/*        src="https://www.youtube.com/embed/Kk1nsDuttwE"*/}
            {/*        title="Пополнение счета форекс через крипту" frameBorder="0"*/}
            {/*        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*/}
            {/*        allowFullScreen></iframe>*/}
            {
                type !== 'stock' && <iframe style={{marginTop: '8px'}} width="100%" height={mediaQuery ? "432" : "211"}
                                 src="https://www.youtube.com/embed/WOwDw5fIAN4"
                                 title="Инструкция - регистрация, пополнение, ввод и вывод" frameBorder="0"
                                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                 allowFullScreen></iframe>
            }
            {
                type === 'stock' && <video controls style={{marginTop: '8px'}} width="100%" height={mediaQuery ? "432" : "211"}
                                 src="https://copyten.ru/docs/video/%D0%A0%D0%B5%D0%B3%D0%B8%D1%81%D1%82%D1%80%D0%B0%D1%86%D0%B8%D1%8F_%D1%83_%D0%B1%D1%80%D0%BE%D0%BA%D0%B5%D1%80%D0%B0_%D1%84%D0%B8%D0%BD%D0%B0%D0%BC.mp4"
                                 title="Инструкция - регистрация, пополнение, ввод и вывод"
                                 ></video>
            }

            {/*<div className={s.box_step_1_item}>*/}
            {/*    <p className={s.box_step_1_title}>Заполните поля <span>латинскими буквами</span></p>*/}
            {/*</div>*/}
            {/*<div className={s.box_step_1_item}>*/}
            {/*    <p className={s.box_step_1_title}>Запомните свой <span>пароль от кабинета</span></p>*/}
            {/*</div>*/}
            {/*<div className={s.box_step_1_item}>*/}
            {/*    <p className={s.box_step_1_title}>Нажмите <span*/}
            {/*        className={s.big_sub_title}>ПОДТВЕРДИТЬ</span> и <span*/}
            {/*        className={s.big_sub_title}>ПРОДОЛЖИТЬ</span></p>*/}
            {/*</div>*/}
            {/*</div>*/}

            {/*<div className={s.box_step_1_right}>*/}
            {/*    <img src={mediaQuery ? img_step_1 : img_step_1_s} alt="image"/>*/}
            {/*</div>*/}
            {/*</div>*/}

            {/*<div className={s.box_step_2}>*/}
            {/*    <Step step={2}/>*/}

            {/*    <div className={`${s.box_step_1_item} ${s.box_step_2_item}`}>*/}
            {/*        <p className={s.box_step_1_title}>Полученный в смс код вводим в поле и нажимаем <br/> <span*/}
            {/*            className={s.big_sub_title}>ПОДТВЕРДИТЬ</span> и <span*/}
            {/*            className={s.big_sub_title}>ПРОДОЛЖИТЬ</span></p>*/}
            {/*    </div>*/}

            {/*    <div className={s.important}>*/}
            {/*        <p className={s.important_p}>*/}
            {/*            ВАЖНО! <br/>*/}
            {/*            <span className={s.important_span}>Если код в смс не пришел, ниже есть кнопка <span*/}
            {/*                className={s.important_sub_span}>"Послать код подтверждения на адрес"</span> и вводим код который пришел на почту.</span>*/}
            {/*        </p>*/}
            {/*    </div>*/}

            {/*    <div className={s.box_step_2_last}>*/}
            {/*        <p className={s.box_step_2_last_title}>На указанную вами почту придет <span*/}
            {/*            className={s.box_step_2_last_white}>письмо с вашими регистрационными данными,</span> по*/}
            {/*            необходимости*/}
            {/*            подтвердите почту. <span*/}
            {/*                className={s.box_step_2_last_green}>На этом регистрация закончена.</span></p>*/}
            {/*    </div>*/}

            {/*    <div className={s.last_img}>*/}
            {/*        <img src={img_step_2} alt="image"/>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default Step1;
