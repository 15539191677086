import React, {useState} from 'react';
import CustomTable from "../../shared/components/customTable";
import CustomStockHistoryTable from "../../shared/components/customStockHistoryTable";
import {useGetHistoryQuery} from "../../store/API/tradersUserApi";
import {useLocation} from "react-router-dom";

const Tabs2 = ({isTrader, dataTrader, isStock}: any) => {
    const location = useLocation()
    const id = location?.pathname?.split('/').at(-2)
    console.log(location?.pathname?.split('/'))
    console.log(id)
    const [page, setPage] = useState(1);
    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const {
        data: dataHistory,
        isLoading: isLoadingHistory
    } = useGetHistoryQuery({id: isTrader ? `trader/${id}` : `account/${id}`, page},
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true
        }
    )

    return (
        isStock ?
        <>
            <CustomStockHistoryTable
                changePage={handleChangePage} page={page}
                isTrader={isTrader}
                data={dataHistory}
                dataTableHead={[
                        'Время открытия', 'Объем', 'Символ', 'Цена открытия', 'Цена закрытия', 'Время закрытия', 'Стратегия', 'Прибыль', 'Комиссия сервиса'
                    ]}
            />
        </>
        :
        <>
            <CustomTable
                changePage={handleChangePage} page={page}
                isTrader={isTrader}
                data={dataHistory}
                dataTableHead={dataTrader ?
                    [
                        'Время', 'Тип', 'Объем', 'Символ', 'Цена', 'Время', 'Цена', 'Комиссия', 'Своп', 'Прибыль'
                    ]
                    :
                    [
                        'Время', 'Тип', 'Объем', 'Символ', 'Цена', 'Время', 'Цена', 'Комиссия', 'Своп', 'Прибыль'
                    ]
                }
            />
        </>
    );
};

export default Tabs2;
