import React, {useLayoutEffect} from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import s from './styles.module.css'

const ChartBalanceDropDown = ({dataChartDrawdownAndGain, currency}: any) => {

        useLayoutEffect(() => {
            let root = am5.Root.new(`chartdiv-dropdown`);

            root?._logo?.dispose()
            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            const chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                maxTooltipDistance: -1
            }));

            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                behavior: "none",
            }));

            cursor.lineY.set("visible", false);
            cursor.lineX.set("stroke", am5.color(0xffffff));
            cursor.lineX.set("strokeWidth", 2);

            const initialValue = dataChartDrawdownAndGain.length === 1 ? [
                {
                    'd': "",
                    'pv': 0,
                    'uv': 0
                }, ...dataChartDrawdownAndGain] : dataChartDrawdownAndGain

            const uniqueKeys = Object.keys(Object.assign({}, ...initialValue))?.filter(f => f !== 'd');
            const data: any = []
            for (let i = 0; i < uniqueKeys?.length; i++) {

                data.push({
                    name: uniqueKeys[i],
                    data: initialValue.map((el: any, index: any) => {
                        const dateandtime: any = el.d ? el.d.split(' ') : null

                        return ({
                            type: uniqueKeys[i],
                            count: el[uniqueKeys[i]],
                            year: index,
                            year2: dateandtime === null ? '' : dateandtime[0]
                        })
                    })
                })
            }

            const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "year",
                startLocation: .5,


                endLocation: 0.5,
                forceHidden: true,
                visible: false,
                tooltip: am5.Tooltip.new(root, {
                    visible: false,
                    forceHidden: true,
                }),
                renderer: am5xy.AxisRendererX.new(root, {
                    forceHidden: true,
                }),

            }));

            const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                numberFormat: `#'${currency}'`,

                maxPrecision: 0,
                renderer: am5xy.AxisRendererY.new(root, {
                    minGridDistance: 20,
                    opposite: true,

                }),

            }));

            yAxis.get("renderer").labels.template.setAll({
                fill: am5.color(0x56CCF2),
                fontSize: "10px",
                rotation: 20,
            });

            xAxis.get("renderer").grid.template.setAll({
                stroke: am5.color(0xffffff),
            })

            yAxis.get("renderer").grid.template.setAll({
                stroke: am5.color(0xffffff),
            })

            function createSeries(name: any, seriesData: any, color: any) {
                const series = chart.series.push(am5xy.LineSeries.new(root, {
                    stroke: color,
                    name: name,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: 'count',
                    categoryXField: "year",
                }));

                const tooltip = series.set("tooltip", am5.Tooltip.new(root, {
                    getFillFromSprite: false,
                    getStrokeFromSprite: true,
                    autoTextColor: false,
                    pointerOrientation: "horizontal"
                }));

                tooltip?.get("background")?.setAll({
                    strokeOpacity: .5,
                    stroke: am5.color(0xffffff),
                    fillOpacity: .5,
                    fill: am5.color(0x1F1F1F),

                })

                tooltip.label.setAll({
                    text: "[bold]{year2}[/]"
                });

                tooltip.label.adapters.add("text", function (text, target) {
                    chart.series.each(function (series) {
                        const tooltipDataItem: any = series.get("tooltipDataItem");

                        if (tooltipDataItem) {
                            text += '[/]' + '\n' + `[${series?.get("stroke")?.toString()}]+${tooltipDataItem.get("valueY")}${currency}`
                        }
                    })
                    return text
                })

                series.data.setAll(seriesData);
                series.appear(1000);
            }

            const color = [am5.color(0x56CCF2), am5.color(0x6FCF97)]

            for (let i = 0; i < data.length; i++) {
                createSeries(data[i].name, data[i].data, color[i]);
                xAxis.data.setAll(data[i].data);
            }

            chart.appear(1000, 100);

            return () => {
                root.dispose();
            };
        }, [dataChartDrawdownAndGain]);

        return (
            <div>
                <div id={'chartdiv-dropdown'} style={{width: "100%", height: "227px"}}></div>
                <div className={s.info_box}>
                    <div className={s.info_box_item}>
                        <div style={{
                            backgroundColor: '#56CCF2'
                        }}/>
                        <p>Баланс</p>
                    </div>
                    <div className={s.info_box_item}>
                        <div style={{
                            backgroundColor: '#6FCF97'
                        }}/>
                        <p>Средства</p>
                    </div>
                </div>
            </div>
        );
    }
;

export default ChartBalanceDropDown;
